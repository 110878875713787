import React from 'react';

import EN from '@/assets/flags/en.svg';
import ES from '@/assets/flags/mx.svg';

const flags = {
  en: EN,
  es: ES,
};
export interface FlagIconProps {
  code: 'en' | 'es';
  size?: number;
}

const FlagIcon: React.FC<FlagIconProps> = ({ code, size = 16, ...props }) => {
  return (
    <img
      src={flags[code]}
      className="anticon"
      alt="flag"
      width={size}
      height={size}
      {...props}
    />
  );
};

export default FlagIcon;
